<template>
  <div class="cooperation container">
    <div class="header">
      <div class="title-box">
        <p class="title">商务合作</p>
      </div>
    </div>
    <p class="title">商务合作</p>
    <div class="content">
      <div class="form">
        <p class="ps">*以下联系方式任填一个</p>
        <el-form class="form-box"
        label-position="right"
          label-width="80px"
          :model="formLabelAlign">
          <el-form-item label="手机号码">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="QQ号码">
            <el-input v-model="form.qq"></el-input>
          </el-form-item>
          <el-form-item label="邮箱地址">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="submitAction">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Input } from 'element-ui'

export default {
  name: 'Cooperation',
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input
  },
  data () {
    return {
      form: {
        phone: '',
        qq: '',
        email: ''
      }
    }
  },
  methods: {
    submitAction () {
      const data = {}
      if (this.form.phone.length > 0) {
        data.phone = this.form.phone
      } else if (this.form.qq.length > 0) {
        data.qq = this.form.qq
      } else if (this.form.email.length > 0) {
        data.email = this.form.email
      } else {
        this.$message.error('请填写任意一个联系方式')
        return
      }

      this.$loading.show()
      this.$api.cooperation(data).then(res => {
        this.$loading.hide()
        this.$message.success(res.msg)
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.cooperation {
  padding-top: 30px;
  padding-bottom: 40px;

  > .header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  > .title {
    padding-top: 40px;
    padding-bottom: 30px;
    font-size: 32px;
    font-weight: 400;
    color: #222;
    text-align: center;
    background-color: #fff;
  }

  > .time {
    font-size: 14px;
    color: #959595;
    text-align: center;
    background-color: #fff;
  }

  > .content {
    margin: 0;
    padding: 36px 30px;
    padding-top: 0px;
    font-size: 16px;
    line-height: 32px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: justify;
	  text-justify: inter-word;
    background-color: #fff;
  }

  .form {
    width: 780px;
    padding: 65px 180px;
    border: 1px solid rgba(216, 216, 216, 0.5);
    border-radius: 16px;
    margin: 0px auto 100px;

    > .ps {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }

    > .form-box {
      margin-top: 40px;
    }

    > .submit {
      margin-top: 68px;
      height: 40px;
      background: rgba(232, 0, 0, 0.8);
      box-shadow: 0px 4px 6px 0px rgba(250, 62, 62, 0.2);
      border-radius: 8px;
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;

    }
  }
}
</style>
